<template>
  <v-container  fluid >
    <app-loader v-if="loading"></app-loader>
      <v-row class="mt-10">
        <v-col style="color:#1C1E4D; text-align: center;">
          <h3 :class="tamañoTitulo">
            <b>regístrate gratis</b>
          </h3>
        </v-col>
      </v-row>
      <ChatComponent />
      <v-row>
        <v-col style="color:#1C1E4D; text-align: center;">
          <h5 :class="tamañoSubtitulo">¿Ya tienes una cuenta? <p style="cursor: pointer;" @click="loginRedirect">Haz clic aquí</p></h5>
        </v-col>
      </v-row>
        <v-stepper elevation="0" v-model="paso" v-if="pasos.length > 1" alt-labels>
          <v-stepper-header style="box-shadow: none;" >
            <template v-for="(item, i) in pasos">
              <v-stepper-step v-if="showStep(item)" class="font-weight-bold" color="#1C1E4D" :complete="paso > item.step"
                :step="item.step">
                {{ item.title }}
              </v-stepper-step>
              <v-divider v-if="i !== pasos.length - 1 && showStep(item)" :key="i"></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content v-for="(item, i) in pasos" :key="i" :step="item.step">
              <v-row>
                <v-col>
                  <v-card outlined style="border-radius: 10px;">
                    <v-card-text>
                      <component :is="item.content" :ref="item.ref" v-if="paso == item.step"
                        :ArrayPersona="{ hola: 'holA' }" @token-hijo="getToken"/>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col style="justify-content: space-between;">
                  <center>
                    <v-btn @click="anterior" v-if="paso != 1" rounded :class="espacioBotones"
                        outlined style="color: #1C1E4D; background-color: white; border-color: #1C1E4D;">
                      Regresar
                    </v-btn>
                    <v-btn v-else rounded :class="espacioBotones" outlined @click="regresar()"
                      style="color: #1C1E4D; background-color: white; border-color: #1C1E4D;">
                      Cancelar
                    </v-btn>
                    &nbsp;
                    <v-btn @click="validar(item.ref)" rounded class="text-capitalize"
                      style="background-color: #1C1E4D; color: white;">
                      {{ paso != 5 ? "Siguiente" : "Registrarme" }}
                    </v-btn>
                  </center>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <AppModalConfirmation :visible.sync="showModal" :imageUrl="imageUrl" />
    <!-- <v-row>
        PERSONA: {{ persona }}
    </v-row>
    <v-row>
        {{ datos.dataPaso1 }} {{ datos.dataPaso2 }} {{ datos.dataPaso3 }} {{ datos.dataPaso4 }} {{ datos.dataPaso5 }} {{ datos.dataPaso6 }}dasd
    </v-row> -->
    <app-footer class="ml-1"></app-footer>
  </v-container>
</template>
<script>
import {mapState} from 'vuex';
import AppFooter from '../../components/AppFooter.vue';
import ChatComponent from "../../components/ChatComponent.vue";
import {Paso1, Paso2, Paso3, Paso4, Paso5} from "./pasos/index"
import jwtDecode from "jwt-decode";
import AppModalConfirmation from '../../components/AppModalConfirmation.vue';

export default {
  components: {AppFooter, ChatComponent, AppModalConfirmation},
  name: "indice",
  props: {
    ArrayPersona: {
      type: Array,
      default: () => {
      }
    }
  },
  data() {
    return {
      loading: false,
      showModal: false,
      imageUrl: require('@/assets/img/confident-woman.png') ,// Ruta a tu imagen en assets
      persona: null,
      pasos: [
        {
          title: "Datos generales",
          content: Paso1,
          step: 1,
          ref: 'Paso1'
        },
        {
          title: "Información personal",
          content: Paso2,
          step: 2,
          ref: 'Paso2'
        },
        {
          title: "Formación",
          content: Paso3,
          step: 3,
          ref: 'Paso3'
        },
        {
          title: "Conocimientos y experiencias",
          content: Paso4,
          step: 4,
          ref: 'Paso4'
        },
        {
          title: "Capacidades",
          content: Paso5,
          step: 5,
          ref: 'Paso5'
        },
        // {
        //     title: "Credenciales",
        //     content: Paso6,
        //     step: 6,
        //     ref: 'Paso6'
        // },
      ],
      paso: 1,
      datos: {
        dataPaso1: null,
        dataPaso2: null,
        dataPaso3: null,
        dataPaso4: null,
        dataPaso5: null,
        // dataPaso6: null
      },
      dataPersona: null,
    };
  },
  methods: {
    getToken(token){
      if (token) {
        this.dataPersona = token.persona
    }},
    loginRedirect() {
      this.$router.push('/login')
    },
    anterior() {
      let paso_anterior = this.paso
      if (paso_anterior == 2) {
        this.paso -= 1
      } else if (paso_anterior == 3) {
        this.paso -= 1
      } else if (paso_anterior == 4) {
        this.paso -= 1
      } else if (paso_anterior == 5) {
        this.paso -= 1
      } else {
        this.paso = 1
      }
      //console.log(this.persona);
      /* this.paso = this.paso - 1 */
    },
    regresar() {
      this.$router.push({name: "login"})
    },
    async savePaso1(datos) {
      try {
        if (datos) {
          const formData = new FormData();
          formData.append("foto", datos.fotoPerfil);
          formData.append("id_estado_civil", datos.estadoFamiliar);
          formData.append("id_tipo_sangre", datos.tipoDeSangreSeleccionado);
          formData.append("id_municipio_residencia", datos.distritoSeleccionado);
          formData.append("telefono", datos.numeroTelefono);
          formData.append("descripcion", datos.descripcionSobreMi);
          formData.append("segundo_nombre", datos.segundoNombre);
          formData.append("tercer_nombre", datos.tercerNombre || null);
          formData.append("segundo_apellido", datos.segundoApellido);
          formData.append("apellido_casada", datos.tercerApellido);
          formData.append("id_pais", datos.nacionalidadSeleccionada);
          const response = await this.services.datosPersona.actualizarPersona(formData, datos.idPersona);
          // Verificar la respuesta del servidor
          if (response.status === 201) {
            this.temporalAlert({
              show: true,
              type: "success",
              message: "Persona actualizada con éxito"
            });
            return true; // Indicar que el guardado fue exitoso
          } else {
            throw new Error("Error al actualizar persona");
          }
        } else {
          this.temporalAlert({
            show: true,
            type: "error",
            message: "El token ya expiró, debe volver a iniciar sesión"
          });
          return false; // Indicar que hubo un problema con el token
        }
      } catch (err) {
        if (err.response.data.err) {
          const error = err.response.data.err
          this.temporalAlert({
            show: true,
            type: "error",
            message: `${error.description}`
          });
          return false
        }
      }
    },
    async validar(value) {
      try {
        if (this.paso === 9) {
          this.pasofinal = true;
          this.scrollToTop();
        } else {
          const ref = this.$refs[value] && this.$refs[value][0];
          if (ref && typeof ref.validar_paso === 'function') {
            if (await ref.validar_paso() === true) {
              const datos = await ref.obtener_data();
              switch (this.paso) {
                case 1:
                  this.datos.dataPaso1 = datos;
                  if (await this.savePaso1(datos)) {
                    this.paso = this.paso + 1;
                  }
                  this.scrollToTop();
                  break;
                case 2:
                  this.datos.dataPaso2 = datos;
                  this.paso = this.paso + 1;
                  this.scrollToTop();
                  break;
                case 3:
                  this.datos.dataPaso3 = datos;
                  this.paso = this.paso + 1;
                  this.scrollToTop();
                  break;
                case 4:
                  this.datos.dataPaso4 = datos;
                  this.paso = this.paso + 1;
                  this.scrollToTop();
                  break;
                case 5:
                  this.datos.dataPaso5 = datos;
                  this.paso = this.paso + 1;
                  this.loading = true;
                  this.registrar();
                  this.scrollToTop();
                  break;
                default:
                  break;
              }
            } 
          } 
        }
      } catch (error) {
        // console.error('Error al validar el paso:', error);
      }
    },
    async registrar() { 
      const response = await this.services.datosPersona.finalizarRegistro(this.dataPersona.id)
      if (response.status == 200) {
        this.loading = false
         this.showModal = true
      }
    },
    showStep(item) {
      if (this.$vuetify.breakpoint.width < 416) {
        return this.paso - 1 === item.step || this.paso === item.step || this.paso + 1 === item.step || this.paso !== 5 && (this.paso + (5 - this.paso))  === item.step
      } else {
        return true
      }
    },
    scrollToTop() {
      // Utiliza scrollIntoView o window.scrollTo en el elemento principal que contiene los pasos
      if (this.$refs.currentStep && this.$refs.currentStep.$el) {
        this.$nextTick(() => {
          this.$refs.currentStep.$el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        });
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  },
  computed: {
    ...mapState(["userInfo"]),
    tamañoTitulo() {
      if (this.$vuetify.breakpoint.width > 500) {
        return 'text-h3 text-uppercase text-bond'
      } else {
        return 'text-h4 text-uppercase text-bond'
      }
    },
    tamañoSubtitulo() {
      if (this.$vuetify.breakpoint.width > 500) {
        return 'text-h5'
      } else {
        return 'text-h6'
      }
    },
    espacioBotones() {
      if (this.$vuetify.breakpoint.width > 290) {
        return 'text-capitalize'
      } else {
        return 'mb-5 text-capitalize'
      }
    },
  },
  async mounted() {
    if (this.userInfo.token) {
      const userDecode = await jwtDecode(this.userInfo.token)
      this.dataPersona = userDecode.persona
    }
  },
  watch: {
    paso: {
      handler(e) {
        if (e != null) {
          /* switch (e) {
              case 1:
                  this.persona = this.dataPaso1
                  break;
              case 2:
                  this.persona = this.dataPaso2
                  break;
              case 3:
                  this.persona = this.dataPaso3
                  break;
              case 4:
                  this.persona = this.dataPaso4
                  break;
              case 5:
                  this.persona = this.dataPaso5
                  break;
              case 6:
                  this.persona = this.dataPaso6
                  break;
              default:
                  break;
          } */
        }
      },
    },
    persona: {
      handler(e) {
        if (e != null) {
          this.paso = this.paso - 1
        }
      }
    }
  },
  created() {
    if (this.userInfo.paso_formulario === '1') {
      this.paso = 2
    } else if (this.userInfo.paso_formulario === '2') {
      this.paso = 3
    } else if (this.userInfo.paso_formulario === '3') {
      this.paso = 4
    } else if (this.userInfo.paso_formulario === '4') {
      this.paso = 5
    } else if (this.userInfo.paso_formulario === '5') {
      this.paso = 5
    } else {
      this.paso = 1
    }
  }
}
</script>

<style lang="scss" scoped>
.bgCard {
  background-color: rgba(239, 157, 66, 0.05) !important;
  padding: 2vw;
  border: 10px solid #ef9d42 !important;
  border-top-left-radius: 50px 50px !important;
  border-bottom-right-radius: 50px 50px !important;
}
.stepper-container {
  max-width: 100%;
  overflow-x: hidden;
}
::v-deep .v-stepper--alt-labels .v-stepper__step{
  flex-basis: 72px !important;
}
</style>
